@font-face {
  font-family: "marvel-saudi-riyal-symbol";
  src: url("/assets/theme/fonts/marvel-saudi-riyal-symbol.eot?1erur8");
  src: url("/assets/theme/fonts/marvel-saudi-riyal-symbol.eot?1erur8#iefix") format("embedded-opentype"), url("/assets/theme/fonts/marvel-saudi-riyal-symbol.ttf?1erur8") format("truetype"), url("/assets/theme/fonts/marvel-saudi-riyal-symbol.woff?1erur8") format("woff"), url("/assets/theme/fonts/marvel-saudi-riyal-symbol.svg?1erur8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  font-family: "marvel-saudi-riyal-symbol" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-inline: 3px !important;
}

.icon-saudi-riyal-symbol:before {
  content: "\e900";
}